
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useDownloadFile, useFilter, usePagination, getUserName, addMoneyMask, formatDate } from "@/libs/utils";
import { Payment } from "@/store/payment/types";

const VPaymentList = defineComponent({
  name: "VPaymentList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de faturamento | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { downloadPDF, downloadCSV } = useDownloadFile();
    const { hasFilters } = useFilter({ ignore: ["tab"] });

    const payments = computed(() => store.state.payment.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(payment: Payment) {
      const items: DropdownItem[] = [
        {
          label: "Relatório (PDF)",
          icon: "fas fa-file-pdf",
          command: async () => {
            const response = await store.dispatch("getPaymentReport", { _id: String(payment.id), isPDF: true });
            if ((response?.status || 500) >= 300) return;
            downloadPDF({ content: response?.data });
          },
        },
        {
          label: "Relatório (CSV)",
          icon: "fas fa-file-csv",
          command: async () => {
            const response = await store.dispatch("getPaymentReport", { _id: String(payment.id), isCSV: true });
            if ((response?.status || 500) >= 300) return;
            downloadCSV({ content: response?.data });
          },
        },

        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "payment-delete", params: { _id: String(payment.id) } },
        },
      ];

      if (payment.ie_status_pagamento !== "P")
        return [
          {
            label: "Visualizar",
            icon: "fas fa-info-circle",
            to: { name: "payment-edit", params: { _id: String(payment.id) } },
          },
          {
            label: "Editar",
            icon: "fas fa-edit",
            to: { name: "payment-edit", params: { _id: String(payment.id) } },
          },
          {
            label: "Faturar",
            icon: "fas fa-tag",
            to: { name: "payment-make", params: { _id: String(payment.id) } },
          },
          ...items,
        ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Faturamento",
        icon: "fas fa-briefcase-medical mr-1",
        to: { name: "payment-list", query: route.query },
      },
      {
        label: "CBHPM",
        icon: "fas fa-hospital-alt mr-1",
        to: { name: "payment-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "payment",
      setPage: "setPaymentPaginationPage",
      updateList: getPayments,
    });

    async function getPayments() {
      loading.list = true;
      await store.dispatch("getPayments");
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "payment-filter", query: route.query });
    else getPayments();

    return {
      ...{ payments, pagination, loading, breadcrumb, hasFilters },
      ...{ getUserName, getDropdownOptions, handleUpdateCurrentPage, getPayments },
      ...{ addMoneyMask, formatDate },
    };
  },
});

export default VPaymentList;
